<template>
  <div
    class="tab-pane fade"
    id="competitor-price-analysis-setting"
    role="tabpanel"
    aria-labelledby="competitor-price-analysis-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Rakip Fiyat Analizi</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <div class="col-6">
          <!-- Username -->
          <WeInput
            name="username"
            label="Kullanıcı Adı"
            v-model="data.username"
          />
          <!-- Username -->
        </div>
        <div class="col-6">
          <!-- password -->
          <WeInput name="password" label="Şifre" v-model="data.password" />
          <!-- password -->
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "PriceAnalysis",
  data() {
    return {
      data: {
        is_active: this.settings["competitor_price_analysis.is_active"],
        username: this.settings["competitor_price_analysis.username"],
        password: this.settings["competitor_price_analysis.password"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSave() {
      this.settings["competitor_price_analysis.username"] = this.data.username;
      this.settings["competitor_price_analysis.password"] = this.data.password;
      this.$parent.$emit("on-save", this.settings);
    },
  },
};
</script>
